import http from '@/services/http/http'


export async function fetchReceipts(bill_id) {
  const url = '/api/v1/subscriber_payments/' + bill_id
  const res = await http.get(url)
  return res.data
}

export async function approvePayment(bill_id){
  const url = '/api/v1/subscriber_payments/' + bill_id + '/approve'
  const res = await http.post(url)
  return res.data
}

export async function deletePayment(bill_id) {
  const url = '/api/v1/subscriber_payments/' + bill_id
  const { data } = await http.delete(url)
  return data
}