<template>

  <div class="license-filter">

    <v-text-field
      solo
      hide-details
      single-line
      placeholder="Search"
      v-model="q"
      @keydown="searchInput"
    ></v-text-field>

    <v-btn btn-no-active icon title="Show All" @click="showAll">
      <v-icon>mdi-view-list</v-icon>
    </v-btn>


    <v-dialog
      scrollable
      v-model="dialog"
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="btn-no-active"
          title="More filter options"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter-menu-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>Filter Subscriber Bills</v-card-title>
        <v-divider></v-divider>

        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <v-card-text>

                <v-checkbox
                  v-model="status"
                  label="Paid"
                  value="paid"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="status"
                  label="Unpaid"
                  value="unpaid"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="status"
                  label="Verifying"
                  value="verifying"
                  hide-details
                ></v-checkbox>

              </v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>
              <v-card-text>
                <div>
                    <v-text-field
                      label="Date From"
                      type="date"
                      hide-details="auto"
                      v-model="date_from"
                    ></v-text-field>
                  </div>
              </v-card-text>

              <v-card-text>
                <div>
                    <v-text-field
                      label="Date To"
                      type="date"
                      hide-details="auto"
                      v-model="date_to"
                    ></v-text-field>
                  </div>
              </v-card-text>
            </v-col>
          </v-row>

        </v-container>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="closeDialog"
          >
            Done
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex'

  let prev_q = '';

  export default {
    components: {
    },
    data: () => ({
      q: '',
      status: null,
      date_from: null,
      date_to: null,
      dialog: false,
      entered: 0,
    }),
    props: {
      value: {
        type: Object
      }
    },
    mounted() {
      let {q, status, type} = this.subscBillsFilter
      this.q = q
      this.status = status
      this.type = type
    },
    methods: {
      reset() {
        this.entered = 0
        this.q = ''
        this.status = null
        this.date_from = null
        this.date_to = null
        this.updateValue()
      },
      searchInput(e) {
        this.entered = 0
        if (this.q && (e.key === 'Enter' || e.keyCode === 13)) {
          this.entered = 1
          this.updateValue()
        } else if (!this.q && prev_q) {
          this.reset()
          this.updateValue()
        } else if (this.q && (e.key === "Escape" || e.keyCode === 27)) {
          this.q = ''
          this.updateValue()
        }
        prev_q = this.q
      },
      showAll() {
        this.reset()
        this.updateValue()
      },
      toggleStatus(s) {
        if (this.status == s)
          this.status = null
        else
          this.status = s
        this.updateValue()
      },
      closeDialog() {
        this.dialog = 0
        this.updateValue()
      },
      clearFilter() {
        this.reset()
        this.closeDialog()
      },
      updateValue() {
        this.setSubscBillsFilter({
          q: this.q,
          status: this.status,
          date_from: this.date_from,
          date_to: this.date_to,
          per_page: 15
        })
      },
      ...mapActions([
        'setSubscBillsFilter',
      ])
    },
    computed: {
      ...mapGetters(['subscBillsFilter'])
    }
  }
</script>

<style lang="scss" scoped>
  .license-filter {
    display: flex;
  }
</style>

