<template>
  <dashboard-layout>

    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        Subscriber Bills
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <subsc-filter/>

    </template>

    <template slot="content">

      <loading-circular v-if="loading"></loading-circular>

      <v-alert type="error" v-if="error" rounded="0">{{ error }}</v-alert>

      <v-alert
        rounded="0"
        class="text-center"
        type="info"
        v-if="!subscriber_bills.length && !loading && !error"
      >
        No data to show
        <span ng-if="hasFilter">
          matching your filter.
          <br/>
          <a @click="reloadAll()">Load all instead.</a>
        </span>
      </v-alert>

      <template v-if="subscriber_bills.length && !error">

        <v-container>

          <h3 class="my-3" v-if="subscriber_bills.length">
            <subsc-heading-text
              :total="total_items"
              :total_paid="total_paid"
              :total_unpaid="total_unpaid"
            ></subsc-heading-text>
          </h3>

          <v-list three-line >
              <v-list-item :key="i" v-for="(k,i) in subscriber_bills" style="border-bottom: 1px solid rgba(255, 255, 255, 0.12)">
                <template>
                  <rand-list-item-avatar
                    :str="k.account_number||''"
                    size="56"
                  >
                  </rand-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      Account Number: {{(k.account_number||"").toUpperCase()}}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="k.phone_number">
                      Phone Number: {{k.phone_number}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="k.email">
                      Email: {{k.email}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Bill Ref. Number: {{k.ref_number.toUpperCase()}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Machine ID: {{k.machine_id}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong v-text="k.currency+' '+k.amount"></strong>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>Billing Date: {{k.created_at | formatDateOnly}}</v-list-item-action-text>
                    <v-list-item-action-text>Due Date: {{k.due_date | formatDateOnly}}</v-list-item-action-text>
                    <v-list-item-action-text v-if="!k.is_verifying">
                      <v-chip class="ma-2" v-bind:class="{ success: k.is_paid, primary: k.is_unpaid}" v-text="k.status.toUpperCase()"></v-chip>
                    </v-list-item-action-text>
                    <v-list-item-action-text v-if="k.is_verifying">
                      <verify-payment :bill="k" @approve="onApproved" @decline="onDeclined"></verify-payment>
                    </v-list-item-action-text>
                    <v-list-item-action-text>
                      <a v-bind:href="k.bill_url" target="_blank" style="text-decoration:none;margin-right:10px">
                        <v-icon color="blue darken-2">mdi-file-document-outline</v-icon>
                      </a>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </template>
              </v-list-item>

          </v-list>

          <div v-if="!loading && subscriber_bills.length" v-observe-visibility="reachedBottom"></div>

          <template
            v-if="page < total_pages || loading"
            class="text-center my-6"
          >
            <div class="text-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </template>

        </v-container>
      </template>

    </template>

  </dashboard-layout>
</template>

<script>

  import  { mapGetters, mapActions } from 'vuex'
  import SubscHeadingText from '@/views/dashboard/recurring-sales/SubscHeadingText'
  import SubscFilter from '@/views/dashboard/recurring-sales/SubscFilter'
  import VerifyPayment from '@/views/dashboard/recurring-sales/VerifyPayment'

  export default {
    name: 'RecurringSales',
    components: {
      SubscHeadingText,
      SubscFilter,
      VerifyPayment
    },
    data() {
      return {
        loading: true,
        error: null,
        page: 1,
        total_items: 0,
        total_paid: 0,
        total_unpaid: 0,
        total_pages: 0
      }
    },
    created() {
      this.reset()
      this.load()
    },
    destroyed() {
      this.reset()
      this.resetSubscBillsFilter()
    },
    methods: {
      reset() {
        this.clearSubscBills()
        this.loading = true
        this.page = 1
      },
      async load() {
        try {
          this.loading = true
          let { page } = this
          let { per_page, status, q, date_from, date_to } = this.subscBillsFilter
          let { total_items, total_pages, total_paid, total_unpaid } = await this.fetchSubscBills({ page, per_page, status, q, date_from, date_to })
          this.total_items = total_items
          this.total_pages = total_pages
          this.total_paid = total_paid
          this.total_unpaid = total_unpaid
        } catch(e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      onApproved() {
        this.reloadAll()
      },
      onDeclined() {
        this.reloadAll()
      },
      hasFilter() {
        var {status, q, date_from, date_to } = this.subscBillsFilter
        return !!(status || q || date_from || date_to)
      },
      reloadAll() {
        this.reset()
        this.resetSubscBillsFilter()
        this.load()
      },
      reachedBottom(visible) {
        if (visible && this.page < this.total_pages && !this.loading) {
          this.page++
          this.load()
        }
      },
      ...mapActions(['fetchSubscBills', 'clearSubscBills', 'resetSubscBillsFilter'])
    },
    computed: {
      subscriber_bills() {
        return this.allSubscBills
      },
      ...mapGetters(['allSubscBills', 'subscBillsFilter'])
    },
    watch: {
      subscBillsFilter() {
        this.reset()
        this.load()
      }
    }
  }
</script>
