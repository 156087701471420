<template>
  <span>

    <span v-if="!subscBillsFilter.q && !subscBillsFilter.status && !subscBillsFilter.date_from && !subscBillsFilter.date_to">
      All
    </span>
    <span v-if="subscBillsFilter.status">
      {{subscBillsFilter.status.toUpperCase()}}
    </span>
   
    <span v-if="subscBillsFilter.date_from">
      Date From: {{subscBillsFilter.date_from | formatDateOnly}}
      <br/>
    </span>
    <span v-if="subscBillsFilter.date_to">
      Date To: {{subscBillsFilter.date_to | formatDateOnly}}
      <br/>
    </span>
    
    <span v-if="subscBillsFilter.q">
      Search results for "{{ subscBillsFilter.q }}"
    </span>

    <span v-if="!loading">
      ({{ total || 0 }} record{{total>1?'s':''}})
      <br/>
      ( TOTAL PAID: {{ total_paid }} )
      <br/>
      ( TOTAL UNPAID: {{ total_unpaid }} )
    </span>

  </span>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: ['loading', 'total', 'total_paid', 'total_unpaid'],
    computed: {
      ...mapGetters(['subscBillsFilter'])
    }
  }
</script>
