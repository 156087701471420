<template>
  <v-dialog
      v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="warning"
        v-bind="attrs"
        v-on="on"
        elevation="1"
        block
        class="mb-3 mt-3"
      >
        Verify Payment
      </v-btn>
    </template>
      <v-card>
        <v-card-title>
          Verify Payment Receipts
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-for="(receipt, i) in receipts" :key="i">
            <img :src="receipt.url" style="width: 100%">
          </div>
        </v-card-text>

        <v-card-actions class="pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-4 float-right"
            @click="approve"
          >
            Approve
          </v-btn>

          <v-btn
            color="red"
            class="mr-4 float-right white--text"
            @click="decline"
          >
            Decline
          </v-btn>

          <v-btn
            class="mr-4 float-right"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
  import { fetchReceipts, approvePayment, deletePayment } from '@/services/http/verify_payment'
  export default {
    name: "VerifyPayment",
    data: () => ({
      loading: true,
      dialog: false,
      receipts: [],
    }),
    props: ['bill'],
    watch: {
      dialog(visible) {
        if (visible) {
          this.load()
        }
      }
    },
    methods: {
      async load(){
        fetchReceipts(this.bill.id).then(data=>{
          this.receipts = data.receipts
        })
      },
      closeDialog() {
        this.dialog = 0
        this.bill.show_payment_dialog = 0
      },
      async approve(){
        const yn = await this.$confirm('Are you sure you want to APPROVE this payment? Your client\'s subscription will automatically resume 5-10 hours after you approve this payment.', {title: 'Confirm'})
        if (yn) {
          this.loading = true
          try {
            var bill_id = this.bill.id
            await approvePayment(bill_id)
            this.$emit("approve", bill_id)
            this.closeDialog()
            this.$toast('Payment successfully approved. ')
          } catch(e) {
            this.$toast('Request submission failed. Your client\'s subscription will automatically resume 5-10 hours.')
            this.error = e
          } finally {
            this.loading = false
          }
        }
      },
      async decline(){
        const yn = await this.$confirm('Are you sure you want to DECLINE this payment? Note: This payment records will be deleted.', {title: 'Confirm'})
        if (yn) {
          this.loading = true
          try {
            var bill_id = this.bill.id
            await deletePayment(bill_id)
            this.$emit("decline", bill_id)
            this.closeDialog()
            this.$toast('Payment successfully declined and deleted')
          } catch(e) {
            console.log(e)
            this.$toast('Request submission failed. ')
            this.error = e
          } finally {
            this.loading = false
          }
        }
      }
    }
  }
</script>